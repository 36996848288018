<template>
  <section>
    <div class="text-center">
      <h1 class="mt-5">
        Selecione um valor para continuar
      </h1>
      <p class="mb-2 pb-75">
        Será adicionado uma taxa de 5% devido aos encargos do Mercado Pago.
      </p>
    </div>

    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col
            v-for="(item, index) in values"
            :key="index"
            md="4"
          >
            <b-card align="center">
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    class="font-medium-1 font-weight-bold text-primary"
                  >R$</sup>
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                  >{{ item.value_text }}</span>
                </div>
              </div>

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                @click.stop="$router.push({ name: 'Mercado Pago (Checkout)', params: { value: item.value } })"
              >
                Selecionar
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <MercadoPagoValueDialog />
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MercadoPagoValueDialog from './MercadoPagoValueDialog.vue'

export default {
  components: {
    MercadoPagoValueDialog,
    BButton,
    BRow,
    BCol,
    BCard
  },
  directives: {
    Ripple
  },
  data: () => ({
    values: [
      {
        value: 5,
        value_text: '5,00'
      },
      {
        value: 10,
        value_text: '10,00'
      },
      {
        value: 15,
        value_text: '15,00'
      },
      {
        value: 20,
        value_text: '20,00'
      },
      {
        value: 25,
        value_text: '25,00'
      },
      {
        value: 30,
        value_text: '30,00'
      }
    ]
  })
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
